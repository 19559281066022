import React, { useState, useCallback, useEffect } from "react"
import {
  LegacyCard,
  FormLayout,
  TextField,
  Layout,
  LegacyStack,
  Form,
  Checkbox,
  ChoiceList,
  Link,
  Text,
} from "@shopify/polaris";
import update from "immutability-helper"
import Divider from "../../../components/divider"
import SmartRules from "../offers/smartRules"
import CreateOfferSteps from "../offers/createOfferSteps"
import DocSave from "../../../components/docSave"
import CardDivider from "../../../components/cardDivider";
const CustomFieldForm = (props) => {
  const {
    token,
    host,
    shop,
    location,
    state,
    setState,
    productPickerShow,
    setProductPickerShow,
    currencySymbol,
    isCreating,
    isEditing,
    isSubmitting,
    hasUnsavedChanges,
    handleFormSubmit,
    handleCustomContentDelete,
    windowDimensions,
  } = props

  
  const addSmartRule = () => {
    const conditions = {
      conditions: {
        all: [
          {
            any: [
              {
                fact: "cartTotal",
                operator: "greaterThanString",
                value: "0.00",
              },
            ],
          },
        ],
      },
      event: {
        type: "foundout",
        params: {
          message: "rule has found out!",
        },
      },
    }
    setState(update(state, { triggers: { $set: conditions } }))
  }
  //const formValues = getValues(fields)
  /* The form layout, created using Polaris and App Bridge components. */
  return (
    <Layout>
      <Layout.Section>
        {/*isCreating && (
        <div style={{ margin: "20px 0 10px -25px" }}>
          <CreateOfferSteps step={2} />
        </div>
        )*/}
        <div style={{ marginBottom: 50 }}>
          <Form disabled={!state.name} onSubmit={handleFormSubmit}>
            <FormLayout>
              <LegacyCard sectioned>
                <FormLayout>
                  <h6>
                    <Text  variant="bodyMd" as="span" fontWeight="semibold">Name Your Field</Text>
                  </h6>
                  <TextField
                    value={state.name}
                    label="Name"
                    labelHidden
                    placeholder="Your shoppers won’t see this."
                    helpText={state.id ? `Unique ID: ${state.id}` : null}
                    onChange={(value) => {
                      setState(update(state, { name: { $set: value } }))
                    }}
                  />
                </FormLayout>
              </LegacyCard>
              <LegacyCard sectioned>
                <FormLayout>
                  <h6>
                    <Text  variant="bodyMd" as="span" fontWeight="semibold">Field name</Text>
                  </h6>
                  <TextField
                    value={state.fieldName}
                    multiline={4}
                    labelHidden
                    label="Field name"
                    onChange={(value) => {
                      setState(update(state, { fieldName: { $set: value } }))
                    }}
                  />
                  <h6>
                    <Text  variant="bodyMd" as="span" fontWeight="semibold">Field placeholder</Text>
                  </h6>
                  <TextField
                    value={state.fieldPlaceholder}
                    label="Field placeholder"
                    multiline={4}
                    labelHidden
                    onChange={(value) => {
                      setState(
                        update(state, { fieldPlaceholder: { $set: value } })
                      )
                    }}
                  />
                  <h6>
                    <Text  variant="bodyMd" as="span" fontWeight="semibold">Character limit</Text>
                  </h6>
                  <TextField
                    value={state.characterLimit}
                    label="Character limit"
                    labelHidden
                    onChange={(value) => {
                      setState(
                        update(state, { characterLimit: { $set: value } })
                      )
                    }}
                  />
                  <h6>
                    <Text  variant="bodyMd" as="span" fontWeight="semibold">Checkbox</Text>
                  </h6>
                  <Checkbox
                    checked={state.checkBox}
                    label="Checkbox"
                    onChange={(value) => {
                      setState(update(state, { checkBox: { $set: value } }))
                    }}
                  />

                  <h6>
                    <Text  variant="bodyMd" as="span" fontWeight="semibold">Checkbox label</Text>
                  </h6>
                  <TextField
                    value={state.checkboxLabel}
                    label="Checkbox label"
                    labelHidden
                    onChange={(value) => {
                      setState(
                        update(state, { checkboxLabel: { $set: value } })
                      )
                    }}
                  />
                  <h6>
                    <Text  variant="bodyMd" as="span" fontWeight="semibold">Save field as</Text>
                  </h6>
                  <ChoiceList
                    title="Save field as"
                    titleHidden
                    choices={[
                      {
                        label: "Order metafield (recommended)",
                        value: "metafield",
                      },
                    ]}
                    selected={state.fieldType}
                    onChange={(value) => {
                      setState(update(state, { fieldType: { $set: value } }))
                    }}
                  />
                  <p>
                    Add a metafield definition to easily see this data on your
                    orders.{" "}
                    <Link
                      external
                      url="https://help.shopify.com/en/manual/custom-data/metafields/metafield-definitions"
                      target="_blank"
                    >
                      Learn more
                    </Link>
                  </p>
                </FormLayout>
              </LegacyCard>
              <LegacyCard sectioned>
                <FormLayout>
                  <h6>
                    <Text  variant="bodyMd" as="span" fontWeight="semibold">Display conditions</Text>
                  </h6>
                  <SmartRules
                    host={host}
                    shop={shop}
                    token={token}
                    addSmartRule={addSmartRule}
                    state={state}
                    setState={setState}
                    productPickerShow={productPickerShow}
                    setProductPickerShow={setProductPickerShow}
                    currencySymbol={currencySymbol}
                    type={"customization"}
                    factsOptionsValue={[
                      { label: "Cart total", value: "cartTotal" },
                      { label: "Cart products", value: "cartProducts" },
                      { label: "Cart product QTY", value: "cartProductQTY" },
                      { label: "Customer", value: "customer" },
                      { label: "Discount code", value: "discountCode" },
                      { label: "Cart value", value: "cartValue" },
                    ]}
                  />
                </FormLayout>
              </LegacyCard>
            </FormLayout>
            <DocSave
              isCreating={isCreating}
              isEditing={isEditing}
              isSubmitting={isSubmitting}
              hasUnsavedChanges={hasUnsavedChanges}
              handleFormSubmit={handleFormSubmit}
              handleCustomContentDelete={handleCustomContentDelete}
              location={location}
            />
          </Form>
        </div>
      </Layout.Section>
      <Layout.Section variant={windowDimensions?.width < 1290 ? "" : "oneThird"}>
        <div
          style={windowDimensions?.width < 1290  ? {} : {
            position: "fixed",
            width: 482,
            overflowY: "auto",
            overflowX: "hidden",
          }}
        >
          <LegacyCard title="Field preview">
          <CardDivider/>
          <div style={{padding:'15px 0'}}>
            <div style={{padding: '30px 15px', background: 'black', minHeight: '385px', display: 'flex', alignItems: 'center', justifyContent: 'center'}}>
              <div style={{width:'100%'}}>
              <LegacyCard sectioned title={state?.fieldName}>
                <LegacyStack gap="3" vertical>
                  <FormLayout>
                    {state?.checkBox && <Checkbox label={state?.checkboxLabel} />}
                    <TextField
                      label="Field placeholder"
                      multiline={4}
                      labelHidden
                      placeholder={state?.fieldPlaceholder}
                    />
                  </FormLayout>
                </LegacyStack>
              </LegacyCard>
              </div>
            </div>
            </div>
          </LegacyCard>
        </div>
      </Layout.Section>
    </Layout>
  );
}

export default CustomFieldForm
